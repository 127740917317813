import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { IAddStaffForm, IEditStaffForm, IEditUserForm, IFreezeAndBlockUserRes, IStaffDetails, IStaffUsersData, IStaffUsersResponse, IUserDataResponse, IUserDetails, } from '../interfaces/user-info';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _httpClient = inject(HttpClient);

  // get users in manage users page
  getUsers( page: number, limit: number, username?: string, email?: string, userId?: string): Observable<IUserDataResponse> {
    if (page == 0) page = 1;

    let optionalParams = [];

    if (email) optionalParams.push(`&email=${email}`);
    if (username) optionalParams.push(`&username=${username}`);
    if (userId) optionalParams.push(`&userId=${userId}`);

    return this._httpClient.get<IUserDataResponse>(`${environment.apiUrl}/v1/user/admin-get-users?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }

  // get user details
  getUserDetails(id: string): Observable<IUserDetails> {
    return this._httpClient.get<IUserDetails>(`${environment.apiUrl}/v1/user/user-details/${id}`);
  }

  // edit user
  editUser(newUserData: IEditUserForm, id: string): Observable<any> {
    return this._httpClient.put(`${environment.apiUrl}/v1/user/edit-user/${id}`, newUserData );
  }

  // freeze user
  freezeUser(id: string): Observable<IFreezeAndBlockUserRes> {
    return this._httpClient.patch<IFreezeAndBlockUserRes>(`${environment.apiUrl}/v1/user/freeze-user/${id}`, {});
  }
  // block user
  blockUser(id: string): Observable<IFreezeAndBlockUserRes> {
    return this._httpClient.patch<IFreezeAndBlockUserRes>(`${environment.apiUrl}/v1/user/block-user/${id}`, {});
  }
  // get staff
  getStaffUsers( page: number, limit: number, username?: string, email?: string, role?: string ): Observable<IStaffUsersResponse> {
    let optionalParams = [];
    if (email) optionalParams.push(`&email=${email}`);
    if (username) optionalParams.push(`&name=${username}`);
    if (role) optionalParams.push(`&role=${role}`);

    return this._httpClient.get<IStaffUsersResponse>(`${environment.apiUrl}/v1/staff/admins?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}`);
  }
  // add staff
  addStaff(data: IAddStaffForm): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl}/v1/staff/create-staff`, data);
  }
  // edit staff
  editStaff(data: IEditStaffForm, id: string): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl}/v1/staff/edit-staff/${id}`, data );
  }
  // get staff details
  getStaffDetails(id: string): Observable<IStaffDetails[]> {
    return this._httpClient.get<IStaffDetails[]>(`${environment.apiUrl}/v1/staff/admins/${id}` );
  }
  // freeze user
  freezeAdmin(id: string): Observable<IFreezeAndBlockUserRes> {
    return this._httpClient.patch<IFreezeAndBlockUserRes>(`${environment.apiUrl}/v1/staff/freeze-admin/${id}`, {} )
  }
  // block user
  blockAdmin(id: string): Observable<IFreezeAndBlockUserRes> {
    return this._httpClient.patch<IFreezeAndBlockUserRes>(`${environment.apiUrl}/v1/staff/block-admin/${id}`, {} );
  }
  
  // block user
  disable2FA(id: string, status: string): Observable<any> {
    return this._httpClient.patch(`${environment.apiUrl}/v1/user/change-2fa-request-status/${id}/${status}`, {} );
  }

}
